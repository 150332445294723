'use client';

import { useState, useEffect, useCallback } from 'react';

export function useActiveSection(containerRef, position = 50) {
	//! State
	const [isActive, setIsActive] = useState(null);

	const onScroll = useCallback(() => {
		if (containerRef.current !== undefined && containerRef.current !== null) {
			const { top } = containerRef.current.getBoundingClientRect();

			setIsActive(top <= window.innerHeight * (position / 100));
		}
	}, [containerRef]);

	useEffect(() => {
		if (typeof window !== undefined) {
			onScroll();

			document.addEventListener('scroll', onScroll);
			document.addEventListener('wheel', onScroll);
		}

		return () => {
			document.removeEventListener('scroll', onScroll);
			document.removeEventListener('wheel', onScroll);
		};
	}, [onScroll]);

	return isActive;
}
