const isEmailInvalid = (email) => {
  return (
    email
      .trim()
      .match(
        /^(?=.{1,64}@.{3,255}$)(?![.!#$%&'*+\-/=?^_{|}~`])([a-zA-Z0-9!#$%&'*+\-/=?^_{|}~`]{0,64}(?:\.[a-zA-Z0-9!#$%&'*+\-/=?^_{|}~`]+)*[a-zA-Z0-9])@(?!(?:.*--.*))([a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*\.[a-zA-Z]{1,63})$/
      ) === null
  );
};

export default isEmailInvalid;
