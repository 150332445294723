export default {
  colors: {
    //! Primary Colors
    gold: '#8C7B4B',

    gray100: '#E3E3E3',
    gray200: '#F2F2F2',
    gray1000: '#9ea2a7',

    dark60: '#1f1f1f99',
    dark100: '#1F1F1F',

    light300: '#EAEDF1',

    coral: '#CD7764',

    royalBlue1000: '#0D1724',
    royalBlue900: '#242D38',
    royalBlue800: '#39414B',
    royalBlue700: '#51575F',
    royalBlue600: '#676C72',
    royalBlue500: '#7D8186',
    royalBlue400: '#939699',
    royalBlue300: '#AAACAD',
    royalBlue200: '#C1C1C0',
    royalBlue100: '#D7D6D4',
    royalBlue50: '#E2E0DD',

    sky300: '#003BB3',
    sky200: '#0055FF',
    sky100: '#E6EEFF',
    flame200: '#D91528',
    light200: '#F4F7FB',
    //! Neutral colors
    white1000: '#FFFFFF',
    white750: '#FAFAF9',
    white500: '#F6F5F3',
    white250: '#F2F0ED',

    //! Secondary Colors
    errorColor: '#DE0016',
    successColor: '#28A94B',
    warningColor: '#FF8936',

    //! Background Color
    backgroundColor: '#ffffff',
  },

  mediaQuery: {
    desktopSizeXL: '2560px',
    desktopSizeL: '1920px',
    desktopSizeM: '1536px',
    desktopSizeS: '1366px',
    tabletSize: '1280px',
    tabletSizeS: '768px',

    desktopSizeXLMin: '2559px',
    desktopSizeLMin: '1919px',
    desktopSizeMMin: '1535px',
    desktopSizeSMin: '1365px',
    tabletSizeMin: '1279px',
    tabletSizeSMin: '767px',
  },
};
