export type RoutesItem = {
  path: string;
  name: string;
  slug?: string;
  isFooter?: string;
  isHeader?: boolean;
};

export default {
  routes: {
    home: {
      path: '/',
      slug: 'home',
      name: 'home',
    },

    //! Recourses
    legend: {
      path: '/legend',
      slug: 'legend',
      name: 'Legend',
      isFooter: 'col-1',
      isHeader: true,
    },

    // Added like in design
    yansnotes: {
      path: '/yansnotes',
      name: "Yan's Note",
      isHeader: false,
      isFooter: 'col-2',
    },

    analytics: {
      path: '/analytics',
      slug: 'analytics',
      name: 'Analytics',
      isFooter: 'col-2',
      isHeader: true,
    },

    product: {
      path: '/product',
      slug: 'product',
      name: 'How it works',
      isFooter: 'col-1',
      isHeader: true,
    },

    aboutUs: {
      path: '/about-us',
      slug: 'about-us',
      name: 'About Us',
      isFooter: 'col-1',
      isHeader: true,
    },

    // Added like in design
    toolkit: {
      path: '/toolkit',
      slug: 'toolkit',
      name: 'Toolkit',
      isHeader: true,
      isFooter: 'col-2',
    },

    pricing: {
      path: '/start-using',
      slug: 'pricing',
      name: 'Start using',
      isFooter: 'col-1',
      isHeader: true,
    },

    blog: {
      path: '/blog',
      slug: '/blog/:slug',
      name: 'Blog',
      isFooter: 'col-2',
      isHeader: false,
    },

    //! Company

    helpCenter: {
      path: '/help-center',
      slug: 'help-center',
      name: 'helpCenter',
    },

    //! Legal
    privacyPolicy: {
      path: '/privacy-policy',
      slug: 'privacy-policy',
      name: 'Privacy Policy',
      isFooter: 'col-3',
    },

    cookiesPolicy: {
      path: '/cookies-policy',
      slug: 'cookies-policy',
      name: 'Cookies Policy',
      isFooter: 'col-3',
    },

    termsOfService: {
      path: '/terms-of-service',
      slug: 'terms-of-service',
      name: 'Terms Of Service',
      isFooter: 'col-3',
    },

    contacts: {
      path: '/contacts',
      slug: 'contacts',
      name: 'Contacts',
      isFooter: 'col-3',
    },
  },
} as {
  routes: Record<string, RoutesItem>;
};
