'use client';
import { useEffect, useState } from 'react';

export const useResizeObserver = (ref) => {
  const [width, setWidth] = useState(0);
  
  useEffect(() => {
    const el = (ref?.current ? ref.current : window);
    const fnWrapper = () => {
      setWidth(el.innerWidth);
    };
    fnWrapper();

    el.addEventListener('resize', fnWrapper);

    return () => {
      el.removeEventListener('resize', fnWrapper);
    };
  }, [ref]);

  return width;
};
