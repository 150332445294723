import axios from 'axios';
import Cookie from 'js-cookie';
import { appConfig } from '@/app/appConfig';

class Api {
  requester = axios.create();

  constructor() {
    this.requester.interceptors.request.use((request) => {
      request.baseURL = appConfig.serverApiUrl;

      if (Cookie.get('lang')) {
        request.headers['Content-Language'] = Cookie.get('lang');
      }

      if (Cookie.get('accessToken')) {
        request.headers.Authorization = `Bearer ${Cookie.get('accessToken')}`;
      }

      return request;
    });

    this.requester.interceptors.response.use(
      (response) => {
        return response.data;
      },
      (error) => {
        return Promise.reject(error.response);
      }
    );
  }

  async get({ url, lang = '', params = {}, accessToken = '' }) {
    params = new Map(Object.entries(params));

    const options = {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    lang && (options.headers['Content-Language'] = lang);

    accessToken && (options.headers.Authorization = `Bearer ${accessToken}`);

    return await this.request(`${url}?${Api.serializeParams(params)}`, options);
  }

  async post(resource, params = {}, lang = undefined, contentType) {
    const options = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': contentType || 'application/json',
      },
      data: params,
    };

    lang && (options.headers['Content-Language'] = lang);

    return await this.request(resource, options);
  }

  async put(resource, params = {}, lang, contentType) {
    const options = {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Language': lang,
        'Content-Type': contentType || 'application/json',
      },
      data: params,
    };

    lang && (options.headers['Content-Language'] = lang);

    return await this.request(resource, options);
  }

  async delete(resource, params = {}) {
    const options = {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      data: { ...params },
    };

    return await this.request(resource, options);
  }

  async request(url, options) {
    return this.requester(url, options);
  }

  static serializeParams(params) {
    const array = [];

    params.forEach((value, key) => {
      array.push(`${key}=${encodeURIComponent(value)}`);
    });

    return array.join('&');
  }
}

export default new Api();
