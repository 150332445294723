import { ComponentType, forwardRef } from 'react';

//* Provider
import UIProvider, { UIConsumer } from '../providers/UIProvider';

//* Helpers
import { getContext } from '@/helpers';

type OptionalContextProps<T, C> = Omit<T, keyof C> &
  Partial<Pick<T, keyof T & keyof C>>;

export default function withUIContext<T>(
  ConnectedComponent: ComponentType<T>,
  properties: string[] | '*' = []
) {
  return forwardRef<
    HTMLElement,
    Omit<T, keyof UIProvider['state'] | keyof UIProvider['methods']>
  >((props, ref) => {
    return (
      <UIConsumer>
        {(context) => {
          let contextProps = properties.length
            ? getContext(context, properties)
            : {};

          return <ConnectedComponent ref={ref} {...props} {...contextProps} />;
        }}
      </UIConsumer>
    );
  });
}
