'use client';
import { RefObject, useEffect, useState } from 'react';

export const useElementVisible = (element: RefObject<HTMLElement>) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (!element.current) return;

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        setIsVisible(entry.isIntersecting);
      });
    });

    observer.observe(element.current);
    return () => {
      observer.disconnect();
    };
  }, [element]);

  return isVisible;
};
