'use client';

import { createContext, useState } from 'react';
import isString from 'lodash.isstring';
import template from 'lodash.template';

//* Translations
import Languages from '@/translations';
import { getByString } from '@/helpers/_functions';

export const LanguageContext = createContext();

const LanguageProvider = (props) => {
	const [state, setState] = useState({
		selectedLang: props.selectedLang || process.env.NEXT_APP_DEFAULT_LANGUAGE || 'en',
		languages: { ...Languages },
	});

	const selectLanguage = (lang) => {
		setState({ selectedLang: lang }, () => {
			localStorage.setItem('lang', lang);
		});
	};

	const translate = (val, vars) => {
		const str = getByString(val, state.languages[state.selectedLang])
		const compiled = isString(str) && str && template(str);

		return (compiled && compiled(vars)) || val || 'Please Set Translate Value';
	};

	const methods = {
		selectLanguage,
		translate,
	};

	return <LanguageContext.Provider value={{ ...state, ...methods }}>{props.children}</LanguageContext.Provider>;
};

export default LanguageProvider;
export const LanguageConsumer = LanguageContext.Consumer;
