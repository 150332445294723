export const POSTS = [
  {
    id: 1,
    title: 'A timeless classic in a new way',
    text: `In the event of an emergency, conveying all necessary details to family members can be challenging or impossible. Many believe that existing methods are either unreliable or cumbersome. However, the solution can be simple: grab a sheet of paper and a black marker. 

Write down a list of your assets, capital, and their locations. Make several copies of this document. Use a black marker to obscure different parts of the information on each copy (this can also be done digitally in MS Word). 

To ensure the information cannot be read through the redactions, photocopy these documents again. Distribute the lists to different family members. 

Now, your family has crucial information that they can use in a critical moment, but they will need to come together to combine the pieces of the puzzle.

Risks: this method is not perfect, but is certainly better than having no plan at all. It works best if the family members are in reasonably good relationships with each other.`,
    metaDescription: `In the event of an emergency, conveying all necessary details to family members can be challenging or impossible. Many believe that existing methods are unreliable.`,
    ordering: 0,
  },
  {
    id: 2,
    title: 'Playing by one side’s rules',
    text: `Don’t get complacent, if you pass bank compliance when opening an account. Banks regularly conduct reviews of their clients and send requests for additional documentation. They may ask for statements or recommendation letters. 

Get ready for it in advance; otherwise, you may not have sufficient time to gather the necessary documents, leading to the refusal of transactions. 

To stay ahead, request recommendation letters, annual, and semi-annual statements from all banks where you hold accounts every six months. Typically, bank inquiries cover the current and previous year, and recommendation letters are valid for six to twelve months. 

To save time, create a template that can be sent to all relevant banks. Most banks accept free-form requests, but some may require you to use their specific templates. In such cases, you’ll need to send separate requests.`,
    metaDescription: `Don’t get complacent, if you pass bank compliance when opening an account. Banks regularly conduct reviews of clients and send requests for documentation.
    `,
    ordering: 2,
  },
  {
    id: 3,
    title: 'Rhino running',
    text: `A rhinoceros runs fast and sees poorly, but its weight makes it a problem for anything in its path. Banks and partners don’t listen to you; they evaluate a person based on their digital profile. Services such as World-Check or Lexis Nexis are the sources of this data. These platforms were created to check counterparties for involvement in illegal financial activities.

However, in reality, they contain information on millions of people. These services are often associated with scandals and data leaks, unjust categorization of individuals as suspicious, and the inclusion of data from irrelevant sources, yet they continue to be widely used.

Do not leave this issue unattended. Request information about yourself regularly, at least every six months.

You don't need to have done something wrong; it's enough for the World-Check algorithm to flag you as suspicious, or for someone in your contacts to be linked to questionable transactions. There's also the risk of database errors or incorrect interpretations.

World-Check is a black box that significantly impacts your capital and assets, potentially leading to loan refusals, blocked transactions, denied bank accounts, and even revocation of residence permits or citizenship. Bank compliance departments work solely with documents and digital traces, so if you face unexplained refusals or biased treatment, World-Check could be the reason.`,
    metaDescription: `A rhinoceros runs fast and sees poorly, but its weight makes it a problem. Banks don’t listen to you; they evaluate a person based on digital profile. `,
    ordering: 3,
  },
  {
    id: 4,
    title: 'The rearview mirror or the history of your money',
    text: `Recently, banking compliance procedures have become increasingly stringent, and it is likely that they will only get worse in the future. Primarily, they request documents proving the continuity of ownership. (Continuity of ownership refers to the history of the origin of your wealth). 

The timeframe for these requests has also changed: initially, regulators were interested in information from the past 6 months, then from the past year, and then from the past 3 years. These days, you may be required to provide data from the past 10 years. 

If you have not yet recovered your data, each day you wait only worsens your situation, as your data becomes outdated while the depth of bank inquiries increases. If you do not start addressing this now, eventually these two trends will intersect, and you will find yourself in an untenable situation. 

Start by recovering copies of documents from the past three years and then delve deeper. Recovering some documents may be difficult or impossible, but by starting this process now, you will already be ahead of many.

*Continuity of ownership - the history of all your assets`,
    metaDescription: `
Recently, banking compliance procedures have become increasingly stringent, and it is likely that they will only get worse in the future.`,
    ordering: 4,
  },
  {
    id: 5,
    title: 'Red button',
    text: `In the subway, on trains, and at workplaces, there is always a red button for emergencies. In human life, the variations of unexpected events are much greater. Nevertheless, statistics show that 99.2% of wealthy families do not have a precise action plan for emergencies. 

Have you thought about what you will do and what plan your family will follow if such an event occurs? To avoid being part of this majority statistic, prepare in advance by developing several future scenarios. Even a simple list of basic actions and assets will prepare you and your family for the most unexpected events. 

Remember to update the plan every six months. In the event of your sudden absence, the survival of your entire family and the preservation of assets will entirely depend on having clearly formulated and planned actions.`,
    metaDescription: `While subways and workplaces have red emergency buttons, 99.2% of wealthy families lack a clear action plan for life's unexpected events. Plan ahead.`,
    ordering: 5,
  },
  {
    id: 6,
    title: 'Ski slopes',
    text: `A black slope can be easier with good snow than a blue slope with bad snow. Often, the reasons behind account freezes or bank-canceled transactions are not immediately clear. Regulatory criteria for deeming client transactions suspicious are vague. 

Sometimes, this judgment is made because a client has a large number of diverse transactions. This can lead to a transaction freeze and a request for additional documentation. 

To avoid this, separate your bank accounts by transaction types, and ideally, conduct different types of transactions through different banks. This reduces the frequency of regular and unexpected requests and helps you manage and respond to bank inquiries. 

For example, use separate accounts for dividends, current expenses, bonuses, and investment income. This approach saves time, is convenient, and reduces compliance risks.

Of course, dividing assets among multiple accounts and banks may increase the complexity of financial planning. Additionally, if you mistakenly mix up the accounts and conduct an atypical transaction, the regulator will likely send you a request.`,
    metaDescription: `Often, the reasons behind account freezes or bank-canceled transactions are not immediately clear. Regulatory criteria for deeming client transactions suspicious are vague.`,
    ordering: 6,
  },
  {
    id: 7,
    title: 'Follow the trail',
    text: `You’ve sent a payment to another country, and it’s taking a long time to reach its destination — this is a common scenario. People check the status of the payment with the sending bank, not realizing that there can be at least four intermediaries involved in the payment chain. 

If any bank in this chain holds the payment longer than usual, it’s highly likely that you’ll be required to provide more detailed KYC (Know Your Customer) data next time, even if the payment goes through this time. 

To be prepared and to track your transactions, you can use services to monitor SWIFT payments. These services track the status of your cross-border transactions in real time and notify you of any delays. 

You can subscribe to paid versions or use free alternatives available online or through Telegram bots. If you don’t want to spend time on this, you can delegate the task to assistant, who can also monitor the transactions. However, keep in mind the risk of disclosing confidential information, as the data will show either the amount or the purpose of the transaction. 

Additionally, a major drawback of these services is that they provide data only in real time and do not maintain statistics for individuals.`,
    metaDescription: `You’ve sent a payment to another country, and it’s taking a long time to reach its destination — this is a common scenario. People check the status of the payment with the sending bank, not realizing that there can be at least four intermediaries involved in the payment chain.`,
    ordering: 7,
  },
  {
    id: 8,
    title: 'The “Alien - Own” strategy: securing your family’s future',
    text: `In today’s world, our lives are diversified not only by types of assets, but also by the jurisdictions where these assets are located. Does ownership of an asset give you your own rights and how can we transfer this capital to heirs without causing them headaches? What rules will govern the inheritance of these assets? 

The simplest solution might be to draft a will for each individual foreign asset located in its respective country. To create this document, you usually don’t need to travel; in most cases, it can be done at a consulate without the other party’s presence. The key is to plan how to transfer this information to your family in the event of an emergency. 

Keep in mind that inheritance can be a lengthy and costly process, and until it is finalized, your heirs will have limited access to the assets. This poses a risk of improper management, potentially leading to asset loss. Additionally, inheritance may be subject to high taxes in the relevant country, adding to the financial burden.`,
    metaDescription: `In today’s world, our lives are diversified not only by types of assets, but also by the jurisdictions where these assets are located.What rules will govern the inheritance of these assets?`,
    ordering: 8,
  },
  {
    id: 9,
    title: 'SWIFT',
    text: `Few people closely track their international payments via SWIFT trackers, though it’s worth doing as it can reveal potential problems. When you send a USD payment, it typically passes through 3-5 banks. If the payment gets delayed, it's likely that one of these banks has compliance or KYC-related questions for the sender.

The bank will request the sender’s client profile from the previous bank in the chain. After reviewing the sender’s identity and finances, the bank either processes the payment, returns it, or freezes it. All banks in the chain can be held responsible if a gray-area or illegal payment is flagged, so they carefully check every transaction to avoid liability.

If a bank requests more information about a payment, all other banks in the chain will be aware. Your sending bank will likely notice and may monitor your future transactions more closely or review your profile.

Tracking payments helps you understand where and why delays occur, even if the payment eventually clears. You can delegate this task to an assistant, as some SWIFT trackers allow tracking by payment number without revealing sensitive details.

It’s also essential to maintain a well-prepared client file with your banks—an important topic for another discussion.`,
    metaDescription: `Few people closely track the movement of their international payments through SWIFT trackers (many of which are publicly available).`,
    ordering: 9,
  },
  {
    id: 10,
    title: 'Cryptocurrency',
    text: `I have nothing against crypto. Quite the opposite. But everything must be done carefully. What’s the main issue with large-scale crypto transactions? In 91% of cases, converting from fiat to crypto and back breaks the chain of ownership, limiting capital mobility. Why?

Imagine you have $1M in business dividends. You have all the necessary documentation, making you a respectable capital owner to banks. Now, you decide to purchase cryptocurrency, like Bitcoin or USDT. This is a standalone transaction. Later, when you exchange the crypto back into fiat (whether tomorrow or in a year), that becomes a separate, independent transaction. These two operations are not formally linked.

As a result, you’ve traded clean capital for crypto, but when exiting, you no longer have the supporting documentation. The chain of continuous ownership is broken. For small sums, this isn't a big issue, but for large amounts, it becomes a red flag for banks. The receiving bank will demand a clear ownership history, and without it, your funds raise suspicion.

Banks today require detailed transparency at every stage of capital accumulation—they want to trace every dollar. When you break the ownership chain with crypto, it becomes difficult, if not impossible, to justify the source of your funds.

While this problem can be avoided, statistics show that only 9% of people worry about it in advance.`,
    metaDescription: `I have nothing against crypto. Quite the opposite. But everything must be done carefully. What’s the main issue with large-scale crypto transactions?`,
    ordering: 10,
  },
  {
    id: 11,
    title: 'Vertical and Horizontal Family Capital',
    text: `There are many myths and illogical behaviors among wealthy families in our field. Many of these have been analyzed in Penguin Analytics.

One prevalent myth is that family money typically transfers vertically (to children). In reality, most transfers are horizontal (to spouses), both planned and unexpected.

Analysts (Owner.One, UBS, and local banks) estimate that over the next 10-20 years, $75-95 trillion in capital will be transferred within families. In the next few years alone, $8-10 trillion will be moved horizontally to spouses. This capital will likely stay at the horizontal level for another 10-15 years before moving vertically to children.

Key insight: much of the capital loss happens during the transfer itself, not afterward, doubling the actual loss rate. Founders need to pass on not only their wealth but also its detailed and formalized history to meet the increasing KYC (Know Your Customer) demands from financial institutions.

Without the founder’s active involvement, it’s almost impossible to reconstruct the capital’s history, leaving family members vulnerable when questions arise about the funds' origins. A surprising 42.86% of capital founders don’t know the details needed for KYC preparation, and an alarming 88.06% of their family members are unaware of these requirements.

Only 4.50% of family heads understand that inaction now merely delays the problem, passing it on to their family. Preparing for this is crucial, but that’s a discussion for another time. Recognizing the complexity of capital transfer is the first step.`,
    metaDescription: `There are many myths and illogical behaviors among wealthy families in our field. Many of these have been analyzed in 'Penguin Analytics'.`,
    ordering: 11,
  },
  {
    id: 12,
    title: 'Your Proxy’s Personal Life – Your Risk',
    text: `Once upon a time, you entrusted part of your wealth to a relative, former classmate, or just a trusted person, making them the formal owner to conceal your involvement. Both of you were relatively young, but as the years went by, your wealth grew, along with social obligations. Now, both of you have families, children, perhaps remarriages, and different life changes, like acquiring new citizenship.

The asset you entrusted them with is no longer entirely yours. If something happens to them, their family may take priority over you, and you could end up with just half, or even one-eighth, of your factory, which they officially own. And if they’ve acquired problematic citizenship, such as U.S. citizenship, returning the asset to you could trigger significant taxes.

Does your proxy have a prenuptial agreement? If it’s in your favor, it might not help much in practice, but if it favors their family, it becomes your problem. Do they have large loans with personal guarantees or risks of subsidiary liability? The list of potential complications goes on.

In many cases, such arrangements are perfectly legal. However, legality doesn’t eliminate the issues described above. Talk to your proxy, assess their situation across all risk zones, model potential consequences, and prepare the necessary documents to protect your interests.

This note doesn’t cover official managers with formal management agreements. That’s a topic for another note.`,
    metaDescription: `Once upon a time, you entrusted part of your wealth to a relative, former classmate, or just a trusted person, making them the formal owner to conceal your involvement.`,
    ordering: 1,
  },
  {
    id: 13,
    title: "I'll Think About That Tomorrow",
    text: `Founders of capital almost never share information about their wealth and assets with their family in advance. In the total majority of cases, they have no opportunity to do so after something has happened.

According to statistics, 89.13% of wealth creators understand everything but do nothing. Why? Most of the responses boil down to the classic "I'll think about it tomorrow" from Scarlett O'Hara in Gone with the Wind.

The transfer of capital happens once in a lifetime. Discovering its outcomes, correcting, and restarting it is almost always impossible. In this field, there are no "drafts".

Another stimulus for the development of the Scarlett syndrome is the founders' realization that the effectiveness of traditional tools is already below the minimally acceptable threshold of reliability, yet they require disproportionately high efforts and resource expenditures.`,
    metaDescription: `Founders of capital almost never share information about their wealth and assets with their family in advance.`,
    ordering: 15,
  },
  {
    id: 14,
    title: 'Game over',
    text: `What if a wealthy capital owner suddenly becomes incapacitated? In 90% of cases, they haven’t shared any information about their wealth with family.

Assets like accounts, safe deposit boxes, brokers, companies, debts, credits, partners, bonds, real estate, stocks, key contacts, proxies, funds, and investments may be lost or become costly to access. Much of the family’s wealth remains unknown.

In families worth up to $100 million, 31% to 73% of assets are lost during the transition — a percentage increasing over the past 30 years. Since the 1990s, information has become more valuable than documents, making information asymmetry and capital loss dramatically worse. Without complete information, accessing assets becomes impossible, even if documents can be reconstructed digitally.

By 2024, 48% of capital owners believed their families couldn’t access their assets due to lack of information, and 23.7% were willing to forfeit up to 25% of their wealth to ensure the rest reaches their family.`,
    metaDescription: `What if a wealthy capital owner suddenly becomes incapacitated? In 90% of cases, they haven’t shared any information about their wealth with family.`,
    ordering: 14,
  },
  {
    id: 15,
    title: 'KYC? Not About Me!',
    text: `For various internal reasons, banks may halt a client's transaction or even block an account. This stems from numerous and often opaque KYC (Know Your Client) requirements, as well as its subtypes like KYT (Know Your Transactions), KYB (Know Your Business), and others.

The majority of clients (82%) have encountered issues with banking or brokerage transactions to some extent. Yet only 17% of them associated these problems with their own KYC.

Of these 17%, only 29% claimed to have a clear understanding of the preventive actions necessary to minimize risks. Merely 4% grasp the depth of issues arising from KYC.

At the first signs of trouble, swift action is essential; otherwise, the situation will worsen, and the bank's demands will escalate. Those who proactively attend to their KYCс— keeping maximum documentation readily available, maintaining an up-to-date Source of Wealth Explanation (SoWE), confirming continuous ownership, and adhering to other formal goodwill parameters — are the ones who come out ahead.`,
    metaDescription: `For various internal reasons, banks — especially — may halt a client's transaction or even block an account.`,
    ordering: 13,
  },
  {
    id: 16,
    title: 'Eliminating Information Asymmetry',
    text: `The vast majority (87.10%) of capital owners do not have precise data and detailed attributes of their assets. They also do not have documents for their assets readily available or direct access to them. They cannot independently transfer data to family members and cannot monitor the actions of trusted persons.

What is the alternative to traditional mechanisms attempting to ensure the transfer of capital and assets to their family members?

It is necessary to achieve confidential information storage, and most importantly — the transfer of information from the founder to family members precisely on time — not earlier or later, but at the exact moment when something happens. Transfer automatically and without the involvement of third parties.

Until 2015, such a result was impossible; now, technology can provide this.

The development of technologies and market demand have led to the creation of a tool like the Repository of Data on Assets and Capital. The repository's effectiveness is up to 99.94%.`,
    metaDescription: `The vast majority (87.10%) of capital owners do not have precise data and detailed attributes of their assets.`,
    ordering: 12,
  },
  {
    id: 17,
    title: 'Revocation of the Presumption of Innocence',
    text: `One day, by a unilateral decision of the bank, the client lost access to their capital and assets. Formally, they are still the client’s property, but without the ability to manage them. Presumption of innocence? Forget it — FATF has nullified that. Your capital is not really yours actually.

Banks or brokers can impose restrictive measures if they have “reasonable doubts.” Compliance departments don’t answer to bank management but directly to national regulators, and your manager can’t help. The burden of proof isn’t on the bank but on the client, who must prove the legal origin of their assets and maintain ownership continuity.

The client will need to gather and submit extensive documentation, yet there’s no guarantee the authorities will be satisfied. And there’s no turning back — you can’t just say, “Give me my money back, I’ll go elsewhere.” Funds could simply be frozen, blocking any transfers, even to your own accounts.`,
    metaDescription: `One day, by a unilateral decision of the bank, the client lost access to their capital and assets.`,
    ordering: 19,
  },
  {
    id: 18,
    title: 'Accidental Toxicity',
    text: `A client can compromise their own bank or brokerage account simply by accepting a payment from a politically exposed person (PEP), a sanctioned individual, or anyone raising concerns related to AML or CFT. Receiving $10k from a "toxic" source could very likely put the entire account at risk, even if it holds $1 million.

The payment might not come directly, but from family members or associates of such individuals. The same issue applies in reverse: if a client sends money to someone flagged as high-risk, they could face consequences.

According to statistics, only 21.3% of clients are aware of the risks associated with incoming payments. Even fewer — just 9.4% — consider the risks of outgoing transactions.

This discrepancy in risk perception is a misconception. For the bank, there's little difference between incoming and outgoing payments, and the incubation period for detecting such transactions can be as long as 1 to 1.5 years.`,
    metaDescription: `A client can compromise their own bank or brokerage account simply by accepting a payment from a politically exposed person (PEP).`,
    ordering: 18,
  },
  {
    id: 19,
    title: 'Information Asymmetry',
    text: `Information asymmetry within wealthy families is a phenomenon that has been known for nearly 100 years. The founder almost never proactively shares or updates family members about assets and capital. But when a negative event occurs, it's often too late. Founders hold an information monopoly, lack reliable channels for passing on this knowledge, and the information itself is not structured for effective transfer.

The problem of information asymmetry within families and the associated capital loss has grown exponentially since the 1990s.

By 2024, 48.0% of capital owners believed their families would be unable to "take over" the assets due to a lack of complete information. Another 23.7% of owners were even willing to give up as much as 25% of their entire wealth in exchange for a guarantee that the rest would securely pass to their family.`,
    metaDescription: `Information asymmetry within wealthy families is a phenomenon that has been known for nearly 100 years.`,
    ordering: 17,
  },
  {
    id: 20,
    title: 'Traditional Actions',
    text: `Typically, a capital founder has considered several options they believe would ensure the transfer of capital to family members in case of unforeseen circumstances. However, their effectiveness as of today is low and continues to decline.

<ol><li>Sharing all information with family members in advance. A critical condition is monthly updates. Neither the founder nor family members are usually willing, able, or equipped to handle this task. The effectiveness of capital transfer is 65.3%.</li><li>Involving third parties (lawyers, advisors, trustees). The founder would need to invest significant time in organizing the process. The effectiveness is 68.3% (excluding the risk of abuse).</li><li>Drafting a will. The issue is not with the document itself but with the procedure of executing the will and obtaining the rights. A large amount of information about each asset is required, which successors simply don’t have. Modern wills need to be updated quarterly or monthly, which no one does. The effectiveness is 61.2%.</li></ol>`,
    metaDescription: `Typically, a capital founder has considered several options they believe would ensure the transfer of capital to family members in case of unforeseen circumstances.`,
    ordering: 16,
    hasList: true,
  },
  {
    id: 21,
    title: 'Why trusted persons will not replace you?',
    text: `Many who begin preparing their wealth for transfer quickly give up, postponing the task once again. Why?

Among families with wealth up to $100 million, the penetration of family offices is only 1.46%. These offices manage non-executive administration for no more than 25% of a client’s assets. In the upper-middle-class segment, the penetration rate is even lower, just 0.73%. Capital founders are overly involved in administering 75%-100% of their assets, while family offices handle only the analytical and background work. The founder is the authorized representative in the external world and is often required to personally perform: visiting bank offices, writing emails to brokers, contacting regulators, and executing other verified actions.

The founder faces a dilemma: continue managing the assets independently or delegate executive rights. It’s easier to “think about it tomorrow”, especially since managing wealth transfers is not something family offices typically want to handle.`,
    metaDescription: `Capital founders often manage 75%-100% of their assets directly, as family offices handle only 25%. Learn why delegating executive rights is a challenge in wealth transfer.`,
    ordering: 22,
  },
  {
    id: 22,
    title: "Psychoanalyst's couch",
    text: `Capital founders are confident in their financial decisions. Yet significant losses still occur when transferring wealth to their family. The root cause lies in psychology and a lack of information.

Family leaders simply set aside the dividends they receive, focusing on their current business. They delay preparations for transferring wealth and assets to their families. They have minimal knowledge about the process. In this field, there are no rough drafts, and actions must be taken with absolute certainty.

The family head needs a counterpart for reflection. The family? Too emotional. Friends or business partners? No one is willing to share examples from their own family and wealth. Consultants? The interaction can be exhausting. Being open about these matters is even more challenging than a session with a psychoanalyst. You are forced to talk not only about personal issues but also financial problems. As a result, capital founders feel alone in their thoughts.`,
    metaDescription: `Wealth transfer can lead to significant losses due to psychological barriers and limited knowledge. Discover why capital founders struggle to discuss family wealth.`,
    ordering: 21,
  },
  {
    id: 23,
    title: '100 in focus',
    text: `Our project focuses 80% on individuals with wealth up to $100 million. Why them specifically? The answer is both deeply philosophical and highly practical.

Economists believe that wealth up to $100 million is the most productive. This is the type of capital that is largely tied to creation, growth, and industrial production. Its owners are primarily driven by the desire to do something meaningful. They are not financial rentiers yet, so they actively create added value for any national economy. Any crisis, whether: political, economic, military, or financial, tends to erode this most productive segment of capital first. Crises are factors beyond their control.

There is also another side to the problem. People in this category are so absorbed into their current ideas, projects, businesses that no pay attention to preserving their wealth and passing it on within the family. This is an absolutely controllable factor, but they unjustifiably treat it as something beyond their control.`,
    metaDescription: `Why is wealth up to $100M crucial? This segment drives growth and creation but is vulnerable to crisis. See why many founders overlook wealth transfer readiness.`,
    ordering: 20,
  },
  {
    id: 24,
    title: 'Safe Deposit Boxes: Convenience or Capital Trap?',
    text: `Many capital owners keep their money assets that can be easily accessed by their families. One such "convenient" option is bank safe deposit boxes, utilized by up to one-third of surveyed wealth holders. Their accessibility creates the illusion of a quick and simple transfer of assets from the founder to family members.

However, only 2.64% of respondents are aware that in several countries, there is a rule: if a safe deposit box is not renewed on time, the bank will open it in the presence of police, and its contents will be placed in a "black bag". Retrieving your money and valuables can only be done in the same manner — under police supervision, which means you will need to explain their origin. A paradox also exists with other popular assets. In an attempt to secure themselves by holding only the most liquid assets, capital owners invest in asset classes that are, in fact, susceptible to capital loss during planned or unplanned transfers to successors.`,
    metaDescription: `Many capital owners rely on bank safe deposit boxes for easy asset transfers, but few know that failure to renew them can lead to complications under police supervision. Discover the hidden risks of popular liquid assets during wealth transfers.`,
    ordering: 23,
  },
  {
    id: 25,
    title: 'Tell Your Own Story',
    text: `Banks and financial institutions form perceptions about you based on your profile, which depends on provided  information. But, many capital owners overlook this opportunity. Incomplete information can lead to service denial or account freezes. The client profile begins with a Source of Wealth Essay (SoWE), which narrates the story of your income.

Yet, a staggering 92% of wealth holders ignore this option. The SoWE outlines the origins and timeline of family wealth, highlighting key milestones in capital accumulation. A well-crafted SoWE minimizes future inquiries. 93.10% of wealth holders believe they have a SoWE fail to realize that the few pages they submit in response to bank requests are not enough and can serve as a red flag for them.

The SoWE should be prepared in the language of the countries where family assets are held and share it with successors. While children may not have their own capital history, they can benefit from yours.`,
    metaDescription: `Create a comprehensive Source of Wealth Essay (SoWE) to strengthen your financial profile and avoid account freezes. Most wealth holders overlook this essential document, but a detailed SoWE can reduce future inquiries and safeguard your legacy for family members. Learn why your story matters`,
    ordering: 24,
  },
];

export const ANNOUNCMENTS = [
  {
    title: 'How to safeguard against misconduct by asset managers?',
  },
  {
    title: 'How should I approach discussing wealth with my family?',
  },
  {
    title:
      'What strategies can be employed to create a positive impression with the Compliance Department?',
  },
  {
    title:
      'How to streamline and organize the management and storage of financial information?',
  },
];

export enum ACTags {
  FROM_PT = 'Subs_form_PT',
  FROM_TOOLKIT = 'Subs_form_toolkit',
  FROM_FOOTER = 'Subs_form_footer',
}
