import {
  forwardRef,
  ComponentType,
} from 'react';

//* Provider
import { LanguageConsumer } from '../providers/LanguageProvider';
import type LanguageProvider from '../providers/LanguageProvider';

//* Helpers
import { getContext } from '@/helpers';


const withLanguageContext = <T,>(
  ConnectedComponent: ComponentType<T>,
  properties: string[] | '*' = []
) => {
  return forwardRef<HTMLElement, Omit<T, 'translate'>>((props, ref) => {
    return (
      <LanguageConsumer>
        {(context) => {
          let contextProps = properties.length
            ? getContext(context, properties)
            : {};

          return <ConnectedComponent ref={ref} {...props} {...contextProps} />;
        }}
      </LanguageConsumer>
    );
  });
};

export default withLanguageContext;
